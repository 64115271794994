export default {
  text: '#202020',
  text2: '#e8dfd6',
  primary: '#202020',
  secondary: '#000000',
  tertiary: '#e8dfd6',
  background: 'white',
  backgroundSecondary: '#65594f',
  light: '#FFF',
  dark: '#050707'
}
