export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      padding: ['', '', '', '1rem 1rem'],
      backgroundColor: '#ffffffeb',
      display: 'flex',
      justifyContent: 'center',
      '.hamburger': {
        borderRadius: '1000px',
        background: 'none',
        ml: '1rem',
        mr: '0rem',
        padding: '0.75rem',
        '> div': {
          backgroundColor: 'primary',
          height: '3px'
        },
        'div:nth-of-type(2)': {
          width: '70%',
          height: '3px'
        }
      },
      '.smallNavMenu': {
        // backgroundColor: '#ffffffba',
        padding: '0.5rem',
        '.react-reveal': {
          display: 'flex',
          flexDirection: 'row'
        },
        '> div': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          a: {
            color: 'text',
            color: 'text',
            fontWeight: '400',
            textTransform: 'uppercase'
          }
        },
        '.order': {
          a: {
            padding: '0.5rem 1rem',
            color: 'white',
            backgroundColor: 'primary',
            borderRadius: '50px',
            fontWeight: 'bold'
          }
        }
      },
      color: 'black'
    },

    '.containerScrolled': {
      backgroundColor: '#ffffffeb',
      color: 'text',
      padding: '0rem',
      boxShadow: '0px 0px 24px 0px rgb(0 0 0 / 15%)',
      borderBottom: 'none',
      '.smallNavMenu': {
        '> div': {
          borderColor: 'black',
          a: {
            color: 'text',
            fontWeight: '400',
            textTransform: 'uppercase'
          }
        }
      },
      '.hamburger': {
        borderRadius: '1000px',
        background: 'none',
        ml: '1rem',
        mr: '0rem',
        padding: '0.75rem',
        '> div': {
          backgroundColor: 'primary',
          height: '2px'
        },
        'div:nth-of-type(2)': {
          width: '70%'
        }
      }
    },
    '.order-online': {
      a: {
        padding: '0.5rem 1rem',
        color: 'white !important',
        backgroundColor: 'primary',
        borderRadius: '50px',
        fontWeight: 'bold'
      }
    },

    '.hamburgerOpen': {
      borderRadius: '1000px',
      background: 'none',
      padding: '0.75rem',
      borderColor: 'white',
      div: {
        backgroundColor: 'white'
      }
    },
    'li.navItem': {
      m: '0.4rem',
      a: {
        color: 'text',
        fontFamily: 'body',
        fontSize: ['0.9rem', '0.9rem', '0.9rem', '0.8rem', '1.05rem', '1.1rem', '1.2rem'],
        fontWeight: 'bold',
        letterSpacing: '0px',
        textTransform: 'capitalize'
      }
    },

    '.logoLocationContainer': {
      position: 'static',
      margin: '0rem auto 0rem 0rem'
    },
    '.logo': {
      img: {
        filter: 'unset',
        maxHeight: '60px'
      }
    },
    '.logoScrolled': {
      img: {
        // filter: 'brightness(0) invert(1)',
        maxHeight: '50px'
      }
    },
    '.navMenuLogo': {
      // variant: 'customVariants.borderCorners',
      padding: '3rem 0rem',
      img: {
        maxHeight: ['80px', '100px'],
        filter: 'unset'
      }
    },

    '.navMenuOpen': {
      color: '#fceed7',
      backgroundColor: 'black',
      '.navItem a': {
        color: 'white'
      },
      'svg path': {
        fill: 'white'
      }
    },
    '.smallNavHeading': {
      color: 'white'
    }
  },

  footer: {
    '.logo': {
      // filter: 'brightness(0) invert(1)',
    },
    borderTop: 'solid 2px',
    borderColor: 'primary',
    '.multiButtonContainer': {
      a: {
        variant: 'buttons.secondary'
      }
    },
    '.gonationLogo': {
      width: 'fit-content'
    }
  },

  ctaWidget: {
    background: 'none',
    boxShadow: ['', '', 'unset'],
    a: {
      backgroundColor: 'primary',
      borderRadius: '500px'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['2rem', '2.5rem', '3rem', '3rem', '3rem'],
    fontWeight: 'normal',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    fontFamily: 'heading'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.2rem', '1.4rem', '1.7rem'],
    marginBottom: '1.5rem',
    color: 'grey',
    border: 'none',

    fontWeight: 'bold'
  },
  text: {
    // textAlign: 'center',
    lineHeight: '1.75',
    fontSize: ['1rem', '1.1rem', '1.2rem', '1.3rem', '1.5rem'],
    '*': {
      fontSize: 'inherit'
    },
    p: {
      lineHeight: '1.75',
      // textAlign: 'center',
      fontSize: 'inherit',
      color: 'inherit'
    }
  },

  pageHero: {
    '.title': {
      fontSize: ['2.5rem', '2.5rem', '3rem', '3rem', '4rem'],
      bottom: ['20vh', '', '5vh'],
      right: ['1rem', '', '6rem']
    }
  },

  sideBySide1: {
    height: ['', '', '80vh'],
    '.content': {
      width: ['100%', '', '40%']
    },
    '.imageContainer': {
      width: ['100%', '', '60%'],
      img: {
        maxHeight: 'unset'
      }
    },
    '.title': {
      variant: 'customVariants.title',

      border: 'none',
      margin: '0rem 0rem 1rem',
      padding: '0rem',
      textAlign: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    flexDirection: ['column', '', 'row'],
    'div.content': {
      padding: '2rem',
      textAlign: ['left', '', 'right'],
      order: ['', '', '2'],
      alignItems: ['flex-start', '', 'flex-end']
    }
  },

  sideBySide3: {
    overflow: 'hidden',
    flexDirection: ['', '', 'row'],
    '.imageContainer': {
      order: '2',
      width: ['', '', '60%'],
      height: ['', '', '95vh'],
      position: 'relative',
      '::before': {
        content: ['none', 'none', "''"],
        border: 'solid 3px',
        borderColor: 'grey',
        position: 'absolute',
        height: '85%',
        width: '50vw',
        left: '10%',
        top: '15%'
      },
      img: {
        // height: '100vh',
      },
      '.image1': {
        position: ['', '', 'absolute'],
        top: '0%',
        left: '25%',
        width: ['', '', '75%'],
        height: ['', '', '75%']
      },
      '.image3': {
        position: ['', '', 'absolute'],
        bottom: '10%',
        width: ['', '', '60%'],
        height: ['', '', '50%']
      }
    },

    '.content': {
      order: '2',
      width: ['', '', '40%'],
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      alignItems: 'flex-start',
      textAlign: 'left',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      },
      '.ctaButton': {
        variant: 'buttons.primary'
      }
    }
  },

  sectionBlock: {
    '.box': {
      width: ['100%', '', 'calc(50% - 2rem)'],
      margin: ['1rem 0rem', '', '1rem'],
      borderRadius: '0px',
      '.image': {
        borderRadius: '0px',
        height: '60vh',
        objectFit: 'cover',
        marginBottom: '5vh'
      },
      '.title': {
        variant: 'customVariants.title',
        // fontSize: ['2.5rem', '3rem', '4rem', '5rem', '6rem'],
        padding: '0rem 2rem'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        padding: '0rem 2rem'
        // color: 'primary',
      },
      '.text': {
        variant: 'customVariants.text',
        padding: '0rem 2rem',

        textAlign: 'left',
        fontSize: ['1rem', '1.1rem', '1.2rem', '1.3rem', '1.5rem'],

        p: {
          textAlign: 'left'
        }
      },
      a: {
        variant: 'buttons.primary',
        margin: '2rem auto 0rem 2rem'
      }
    },

    '.box:nth-of-type(1)': {
      '.image': {
        order: ['', '', '5'],
        marginTop: ['', '', '5vh'],
        marginBottom: ['', '', '0rem']
      }
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    padding: ['3rem 1rem 0rem', '', '140px 3rem 0rem'],
    height: 'unset',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '.slick-slider, .slick-list, .slick-slide, .slick-slide > div, .slick-slide img': {
      height: '70vh !important',
      width: '100%'
    },
    '.hero_content_container': {
      position: 'static',
      order: '2',
      width: '100%',
      maxWidth: 'unset',
      transform: 'unset',
      position: 'relative',
      top: ['-3rem', '-4rem', '-5rem', '-6rem', '-7rem', '-8rem']
    },

    '.title': {
      variant: 'customVariants.title',
      fontWeight: 'bold',
      fontSize: ['3rem', '4rem', '5rem', '6rem', '7rem', '8rem'],
      fontFamily: 'heading',
      marginBottom: '0rem',
      color: 'text2',
      textShadow: '2px 2px 2px black'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['3rem', '4rem', '5rem', '6rem'],
      fontFamily: 'display2',
      color: 'tertiary',
      fontWeight: 'normal',
      marginBottom: '0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      fontSize: ['1rem', '1.1rem', '1.2rem', '1.3rem', '1.5rem']
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {},

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    height: ['100vh', '100vh', '100vh', '100vh', '100vh'],
    '.section': {
      maxWidth: 'unset'
    },
    '.title': {
      color: '#343434',
      fontFamily: 'heading',
      fontSize: ['2.5rem', '3.5rem', '4.5rem', '6rem', '7rem', '8rem']
    },
    '.subtitle': {
      color: 'white',
      fontSize: ['2rem', '3rem', '3.5rem', '4rem']
    },
    '.text': {
      order: '3',
      mb: '2rem',
      '> *': {
        color: 'background',
        fontSize: ['1rem', '1.1rem', '1.2rem', '1.3rem', '1.5rem'],
        maxWidth: '600px'
      }
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide3',
    mt: ['35vh', '', '30vh'],
    'div.content': {}
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide2',
    mb: '10rem',
    '.image3': {
      mt: '5rem',
      mb: '-5rem'
    }
  },

  homepageCatering: {
    variant: 'customVariants.homepageMenu',
    marginTop: ['0rem', '0rem', '0rem', '0rem', '0rem']
  },

  homepageContact: {
    variant: 'customVariants.sideBySide1',
    height: ['', '', '100vh'],
    '.section > div': {
      textAlign: 'center',
      alignItems: 'center',
      backgroundColor: '#00000091',
      padding: '2rem'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'white',
      width: '100%',
      justifyContent: 'center'
    },
    '.linksContainer': {
      width: '100%',
      mt: '2rem',
      justifyContent: 'center'
    }
  },

  homepageShout: {
    backgroundColor: 'background',
    padding: ['0rem 1rem 1rem', '', '', '0rem 0.5rem 1rem'],
    '.title': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      backgroundColor: 'secondary',
      fontFamily: 'body'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.76rem', '', '2.5rem'],
      order: ['4', '', '3'],
      m: '0.5rem',
      fontWeight: 'normal',
      padding: ['', '', '1.5rem'],
      background: 'none',
      border: 'solid 1px black',
      color: 'text'
    },
    '.date': {
      backgroundColor: 'tertiary',
      color: 'black',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem'
    },
    '.imageContainer': {
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer'
    },

    margin: '0rem 0rem',
    '.shoutCTAS': {
      border: 'solid 8px',
      borderColor: 'background'
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.section': {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      textAlign: 'left',
      maxWidth: '1200px',
      m: 'auto'
    },
    '.title': {
      variant: 'customVariants.title',
      mb: '2rem'
    },
    '.text': {
      variant: 'customVariants.text'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {},

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    textAlign: 'left',
    position: 'relative',
    alignItems: 'flex-start',
    '> *': {
      zIndex: '1'
    },
    '::before': {
      content: ['none', 'none', "''"],
      backgroundColor: 'rgb(0 0 0 / 85%)',
      width: '50%',
      height: '100%',
      position: 'absolute',
      top: '0%',
      right: '0%',
      zIndex: '0'
    },
    '.title': {
      variant: 'text.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      maxWidth: '500px',
      width: '100%'
    },
    '.subtitle': {
      variant: 'text.subtitle',
      maxWidth: '500px',
      width: '100%',
      opacity: '0.7',
      mb: '2rem'
    },
    '.text': {
      maxWidth: '500px',
      width: '100%',
      mb: '2rem'
    },
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    pl: ['', '', '52.5%'],
    form: {
      maxWidth: '500px'
    },
    '.inputField, .textField ': {
      fontWeight: '300',
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'white',
      borderRadius: '0px',
      padding: '1.5rem 0.5rem',
      color: 'light',
      fontFamily: 'heading',
      '::placeholder': {
        color: 'white'
      }
    },
    textarea: {
      '::placeholder': {
        color: 'text',
        fontWeight: '300',
        fontFamily: 'heading'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      padding: '0.5rem 1rem',
      height: 'unset',
      width: 'auto',
      marginTop: '1.5rem',
      ':hover': {
        borderColor: 'dark',
        backgroundColor: 'dark'
      }
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      color: 'white',
      width: '100%',
      backgroundColor: 'secondary',
      padding: '0.5rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem'],
      textAlign: 'center',
      alignItems: 'center'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ===============================
  // ? ====  Italian Market page  ====
  // ? ===============================

  italianMarket: {
    padding: '2rem 2rem 2rem',
    '.content': {
      justifyContent: 'flex-start'
    },
    '.linksContainer': {
      mt: '2rem'
    },
    '.title': {
      variant: 'customVariants.title',
      border: 'none',
      margin: '0rem 0rem 1.5rem',
      padding: '0rem',
      textAlign: 'left'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      width: '100%',
      textAlign: 'center',
      justifyContent: 'center'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  italianMarketCentered: {
    variant: 'customVariants.italianMarket',
    minHeight: '90vh',
    '.section': {
      color: 'white',
      height: 'fit-content',
      margin: 'auto',
      backgroundColor: '#38160399',
      p: '3rem 2rem',
      '.text': {
        '.ql-size-large': {
          fontSize: '1.5em'
        }
      },
      '.ctaButton': {
        backgroundColor: 'white',
        color: 'black',
        fontSize: '1.25rem',
        p: '1rem 2.5rem'
      },
      '.secondaryCtaButton': {
        p: '1rem 2.5rem',
        fontSize: '1.25rem'
      }
    }
  },

  // ? =============================
  // ? ====  Custom Cakes page  ====
  // ? =============================

  customCakes: {
    variant: 'customVariants.sideBySide1',
    padding: ['1rem', '', '', '2rem', '3rem'],
    '.slick-arrow': {
      borderRadius: '100px',
      height: '20px !important',
      width: '20px !important',
      p: '0.5rem'
    },
    'div.content': {
      '.title': {
        textAlign: 'left'
      }
    },
    'div.lazyload-wrapper': {
      width: ['', '', '60%'],
      borderRadius: '100%',
      overflow: 'hidden'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    '.boxesHeading': {
      display: 'none'
    },
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.textContent': {
      backgroundColor: 'transparent'
    },
    '.box': {
      flexGrow: '1'
    },
    '.imageFill': {
      paddingBottom: '65%'
    },
    '.imageContainer': {}
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
