export default {
  primary: {
    backgroundColor: 'secondary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'secondary',
    borderRadius: '0px',
    textTransform: 'capitalize',
    fontFamily: 'heading',
    fontWeight: 'bold',
    borderRadius: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: ['1rem', '', '1.1rem'],
    padding: '1rem 2rem',
    ':hover': {
      backgroundColor: '#320f0a'
    }
  },
  secondary: {
    backgroundColor: 'tertiary',
    color: 'text',
    border: 'solid 2px',
    borderRadius: '500px',
    borderColor: 'tertiary',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: ['0.9rem', '', '1rem'],
    padding: '1rem 2rem',
    fontFamily: 'heading',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
